import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
export default class Navbar extends Component {
    render() {
        return (
            <div className='navbar'>
                <NavLink activeClassName='navbar__link--active' className='link' to='/home'><div>home</div></NavLink>
                <NavLink activeClassName='navbar__link--active' className='link' to='/about'><div>about</div></NavLink>
                <NavLink activeClassName='navbar__link--active' className='link' to='/media'><div>media</div></NavLink>
                <NavLink activeClassName='navbar__link--active' className='link' to='/resume'><div>resume</div></NavLink>
                {/* <NavLink activeClassName='navbar__link--active' className='link' to='/creations'><div>creations</div></NavLink> */}
            </div>
        )
    }
}
