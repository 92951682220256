import './App.css';
import { Router, Route } from 'react-router-dom';
import Navbar from './Navbar';
import Loadable from 'react-loadable';
import Spinner from './Spinner';
import { createBrowserHistory } from 'history';
import { Dimension } from './SVG'
import Social from './Social'
const history = createBrowserHistory();
// Initialize google analytics page view tracking
history.listen(location => location);

function Loading(props) {
  if (props.pastDelay) {
    return <Spinner />
  } else {
    return <Spinner />
  }
}
const Cover = Loadable({
  loader: () => import('./Cover'),
  loading: Loading,
  delay: 200
});

const Resume = Loadable({
  loader: () => import('./Resume'),
  loading: Loading,
  delay: 200
});

const Media = Loadable({
  loader: () => import('./Media'),
  loading: Loading,
  delay: 200
});

const About = Loadable({
  loader: () => import('./About'),
  loading: Loading,
  delay: 200
});

function App() {
  return (
    <Router history={history}>
      <div className="App">
        <Route exact path='/' component={Cover} />
        <Route exact path='/home' component={Cover} />
        <Route exact path='/resume' component={Resume} />
        <Route exact path='/media' component={Media} />
        <Route exact path='/about' component={About} />
        <Navbar />
        <div onClick={() => window.location.href = '/'}><Dimension /></div>
        <Social />
      </div>
    </Router>
  );
}

export default App;
