import React from 'react'
import { RiInstagramFill, RiLinkedinBoxFill, RiTwitterFill, RiGithubFill } from 'react-icons/ri'
import { Singularity } from './SVG'

export default function Social() {
    return (
        <div className='social'>
            <a href='https://www.instagram.com/christiancruzgodoy/'><RiInstagramFill size='30px' color='black' /></a>

            <a href='https://www.linkedin.com/in/christian-cruz-godoy/'><RiLinkedinBoxFill size='30px' color='black' /></a>

            <a href='https://twitter.com/christiancgodoy'><RiTwitterFill size='30px' color='black' /></a>

            <a href='https://github.com/christiancruuz'><RiGithubFill size='30px' color='black' /></a>

            <a href='https://www.uni-ke.com/i0/profile/solomund'><div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'black', width: '25px', height: '25px', borderRadius: '5px' }}><Singularity /></div></a>
        </div>
    )
}
