import React from 'react';
import { Dimension2 } from './SVG'
export default () => {
  return (
    <div className='spinner-container'>
      <div className='spinner-logo'>
        <Dimension2 />
      </div>
    </div>
  );
};
